const messageNotificationType = {
    REMOVE_USER: 'REMOVE_USER',
    ADD_USERS: 'ADD_USERS',
    PUBLIC_GROUP: 'PUBLIC_GROUP',
    PRIVATE_GROUP: 'PRIVATE_GROUP',
    ADD_ADMIN: 'ADD_ADMIN',
    REMOVE_ADMIN: 'REMOVE_ADMIN',
    CHANGE_OWNER: 'CHANGE_OWNER',
    ACCEPT_FRIEND: 'ACCEPT_FRIEND',
    INVITE_TO_GROUP: 'INVITE_TO_GROUP',
    LEAVE_GROUP: 'LEAVE_GROUP',
    PIN_MESSAGE: 'PIN_MESSAGE',
    UNPIN_MESSAGE: 'UNPIN_MESSAGE',
    CREATE_GROUP: 'CREATE_GROUP',
};

export default messageNotificationType;
